
import Vue from "vue";
// import UserProfile from "@/components/organisms/UserProfile.vue";
import { ConnectedOtherServices, NotificationsFromNolaNovel } from "@/lib/models";

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    // UserProfile,
  },
  computed: {
    isActivePage() {
      return (nameList) => {
        const { name } = this.$route;
        return nameList.includes(name!);
      };
    },
    isConnectedNolaNovel() {
      const connectedOtherServices = this.$store.getters["userModule/connectedOtherServices"] as ConnectedOtherServices;
      return connectedOtherServices.nolaNovel.result;
    },
    unreadNotificationsCount() {
      const unreadNotifications = this.$store.getters["nolaNovelModule/notifications"](
        false
      ) as NotificationsFromNolaNovel;
      return unreadNotifications.items.length;
    },
    unreadNotificationsCountText() {
      const { unreadNotificationsCount } = this;
      return unreadNotificationsCount < 100 ? `${unreadNotificationsCount}` : "99+";
    },
  },
});

interface Props {}

interface Data {}

interface Computed {
  isActivePage(nameList: string[]): boolean;
  isConnectedNolaNovel?: boolean;
  unreadNotificationsCount: number;
  unreadNotificationsCountText: string;
}

interface Methods {}
